<template>
    <layout-modal ref="modal">
        <template #title>
            {{ user?.name }}
        </template>

        <template #default>
            <p class="-mt-2 mb-0">
                {{ $t("portal.card.member_since", { date: iso8601ToDate(user?.create_date) }) }}
            </p>

            <img
                :src="baseUrl + 'api/card/download/qr-code'"
                :alt="$t('portal.card.qr_code')"
                class="my-8 max-w-64 h-auto mx-auto aspect-square"
            />

            <p class="text-larger">
                {{ $t("portal.card.number") }}:<br />
                <span v-html="cardNumberFormated" />
            </p>

            <p class="mt-4">{{ $t("portal.card.valid_until") }}: {{ validUntilFormated }}</p>
        </template>
    </layout-modal>
</template>

<script setup lang="ts">
import type { KadeweUser } from "~/types";

const user = useSanctumUser<KadeweUser>();
const dayjs = useDayjs();
const baseUrl = useBaseUrl();
const modal = ref<InstanceType<typeof LayoutModal> | null>(null);

const iso8601ToDate = (date: string) => {
    return new Date(date).toLocaleDateString("de", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    });
};

const cardNumberFormated = computed(() => {
    return user.value?.card_number?.replace(/(.{4})/g, "$1&nbsp;").trim();
});

const validUntilFormated = computed(() => {
    if (!user.value?.current_card_valid_until) {
        return "";
    }

    return dayjs.utc(user.value.current_card_valid_until).format("MM.YYYY");
});

defineExpose({
    open: () => {
        modal.value?.open();
    },
});
</script>
